import "./InfoCard.css";

function InfoCard({ title, link, linkText, icon }) {
    return (
        <a
            href={link}
            className="text-decoration-none p-3 info-card d-flex flex-column justify-content-center align-items-center fs-1 text-center my-3"
        >
            <div>{icon}</div>
            <h4>{title}</h4>
            <span className="fs-4 direction-ltr">{linkText}</span>
        </a>
    );
}

export default InfoCard;
