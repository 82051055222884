import React from "react";
import "./Slider.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css";
import { useSelector } from "react-redux";
import useAuthenticatedQuery from "../../hooks/useAuthenticatedQuery";
import Loader from "../../components/Loader/Loader";

export default function Slider() {
    const { lang } = useSelector((state) => state.lang);
    const { isLoadin, data } = useAuthenticatedQuery({
        queryKey: ["slider"],
        url: "api/banner",
        config: {},
    });

    if (isLoadin) return <Loader />;

    return (
        <div>
            <Swiper
                slidesPerView={1}
                modules={[Navigation, Pagination, Scrollbar, A11y]}
                navigation={true}
                dir="ltr"
            >
                {data?.result.map((slide) => {
                    return (
                        <SwiperSlide key={slide.banarNo}>
                            <div className="swiper-slider ">
                                <img
                                    src={slide.imgUrl}
                                    alt={
                                        lang === "en"
                                            ? slide.subTitleEn
                                            : slide.subTitleAr
                                    }
                                />
                                <div className="overlay"></div>
                                <div
                                    className="image-content"
                                    dir={lang === "en" ? "ltr" : "rtl"}
                                >
                                    <h2 className="banner-title">
                                        {" "}
                                        {lang === "en"
                                            ? slide.subTitleEn
                                            : slide.subTitleAr}
                                    </h2>

                                    <br />
                                    <p className="fw-bold banner-text">
                                        {lang === "en"
                                            ? slide.parphEn
                                            : slide.parphAr}
                                    </p>
                                </div>
                            </div>
                        </SwiperSlide>
                    );
                })}
            </Swiper>
        </div>
    );
}
