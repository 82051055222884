import './Loader.css'

function Loader() {
    return (
        <div className="min-vh-100">
            <div className="loader mx-auto my-4" style={{direction: "rtl"}}>... L &nbsp; ading</div>
        </div>
    )
}

export default Loader
