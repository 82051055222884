import { useSelector } from "react-redux"
import InputErrorMessage from "../InputErrorMessage/InputErrorMessage"
import { PAYMENT_INPUTS } from "../../data"
import { Fragment } from "react"
import { useForm } from "react-hook-form"
import Input from "../Input/Input"

function PaymentForm({ onSubmit }) {
    const {
        register,
        handleSubmit,
        formState: { errors },
      } = useForm({
        defaultValues: {},
      });
    const {lang} = useSelector(state=> state.lang)
    return (
        <div className="form col-12  p-5 mt-5 mt-lg-0  pt-0 payment-form">
        <h3 className=" p-3">
          {lang === "en" ? "customer details" : "تفاصيل المشتري "}
        </h3>
        <form onSubmit={handleSubmit(onSubmit)}>
          {PAYMENT_INPUTS.map(({ type, rules, en, ar, id }) => (
            <Fragment key={id}>
              <div className="col-lg-6 fs-6" id={id}>
                <Input
                  type={type}
                  {...register(id, rules)}
                  placeholder={
                    lang === "en" ? en.placeholder : ar.placeholder
                  }
                  error={errors[id] && true}
                />
                {errors[id] && (
                  <InputErrorMessage
                    msg={
                      lang === "en"
                        ? en.validations[errors[id].type]
                        : ar.validations[errors[id].type]
                    }
                  />
                )}
              </div>
            </Fragment>
          ))}
          <button className="btn btn-primary fw-bold text-shadow py-2 px-4 lh-md fs-5 rounded-pill">
            {" "}
            {lang === "en" ? "Pay" : "دفع"}
          </button>
        </form>
      </div>
    )
}

export default PaymentForm
