import React, { useEffect } from "react";
import "./About.css";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { useSelector } from "react-redux";
import Loader from "../../components/Loader/Loader";
import useAuthenticatedQuery from "../../hooks/useAuthenticatedQuery";

export default function About() {
  const { lang } = useSelector((state) => state.lang);

  const { isLoading, data } = useAuthenticatedQuery({
    queryKey: ["about"],
    url: "api/about",
    config: {},
  });
  useEffect(() => {
    if (isLoading) return;
    const about = new DOMParser().parseFromString(
      lang === "en" ? data.result.textEn : data.result.textAr,
      "text/html"
    );
    document.getElementById("about").innerHTML = about.body.innerHTML;
  }, [data, lang]);

  if (isLoading) return <Loader />;

  return (
    <section className="about-page">
      <section className="page-title">
        <div className="overlay1"></div>
        <div className="container">
          <div className="title-box text-center align-items-center justify-content-center d-flex">
            <h1 className="text-center">
              {lang === "en" ? "About Us" : "من نحن"}
            </h1>
          </div>
        </div>
      </section>
      <section className="about container">
        <div id="about" className="text-center my-4"></div>
        <ResponsiveMasonry>
          <Masonry columnsCount={5} gutter="7px">
            {data?.result?.aboutPhoto?.map((image) => (
              <img key={image.abPhId} src={image.imgUrl} alt={image.abPhId}/>
            ))}
          </Masonry>
        </ResponsiveMasonry>
      </section>
    </section>
  );
}
