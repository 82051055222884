import React from "react";
import { Col, Row } from "react-bootstrap";
import "./Footer.css";
import Logo from "../Logo/Logo";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { IoHome } from "react-icons/io5";
import { BsExclamationSquareFill, BsJournalRichtext } from "react-icons/bs";
import { MdMiscellaneousServices } from "react-icons/md";
import { BiDollar } from "react-icons/bi";
import { FaHorseHead } from "react-icons/fa6";
import { GiPayMoney, GiReceiveMoney } from "react-icons/gi";
import { RiMailSendLine } from "react-icons/ri";
import useAuthenticatedQuery from "../../hooks/useAuthenticatedQuery";
import { IoIosStarHalf } from "react-icons/io";

const FOOTER_LINKS = [
    {
        text: "الرئيسية",
        en: "Home",
        to: "/",
        icon: <IoHome />,
    },
    {
        text: "من نحن",
        en: "About Us",
        to: "/about",
        icon: <BsExclamationSquareFill />,
    },
    {
        text: "خدماتنا",
        en: "Services",
        to: "/services",
        icon: <MdMiscellaneousServices />,
    },
    {
        text: "المزايدات",
        en: "Bidding",
        to: "/bidding",
        icon: <BiDollar />,
    },
    {
        text: "التقييم",
        en: "Feedback",
        to: "/feedback",
        icon: <IoIosStarHalf />
    },
];

const SERVICES = [
    {
        text: "تدريب",
        en: "Training",
        to: "/trainers",
        icon: <FaHorseHead />,
    },
    {
        text: "البيع",
        en: "Sale",
        to: "/",
        icon: <GiReceiveMoney />,
    },
    {
        text: "الشراء",
        en: "Purchase",
        to: "/",
        icon: <GiPayMoney />,
    },
    {
        text: " كيفية الاستخدام",
        en: "User journey",
        to: "/userjourney",
        icon: <BsJournalRichtext />,
    },
];

const CONTACT = {
    en: [
        {
            text: "Contact",
            to: "/contact",
            icon: <RiMailSendLine />,
        },
        {
            text: "mosalem72@gmail.com",
            to: "mailto:mosalem72@gmail.com",
            icon: <i className="fa-regular fa-envelope"></i>,
        },
        {
            text: "00201061159253",
            to: "tel:00201061159253",
            icon: <i className="bi bi-telephone-forward"></i>,
        },
        {
            text: "00201091160752",
            to: "tel:00201091160752",
            icon: <i className="bi bi-telephone-forward"></i>,
        },
    ],
    ar: [
        {
            text: " تواصل معنا",
            to: "/contact",
            icon: <RiMailSendLine />,
        },
        {
            text: "mosalem72@gmail.com",
            to: "mailto:mosalem72@gmail.com",
            icon: <i className="fa-regular fa-envelope"></i>,
        },
        {
            text: "00201061159253",
            to: "tel:00201061159253",
            icon: <i className="bi bi-telephone-forward"></i>,
        },
        {
            text: "00201091160752",
            to: "tel:00201091160752",
            icon: <i className="bi bi-telephone-forward"></i>,
        },
    ],
};

export default function Footer() {
    const { lang } = useSelector((state) => state.lang);
    const dispatch = useDispatch();

    const { isLoading, data } = useAuthenticatedQuery({
        queryKey: ["socialMedia"],
        url: "api/socialmedia",
        config: {},
    });
    if (isLoading) return <div>123</div>;

    return (
        <footer className="footer">
            <div className="container">
                <Row className="col-md-12 mx-3 mx-md-0">
                    <Col
                        xs="12"
                        md="6"
                        lg="3"
                        className="pb-5 pb-lg-0 ps-4 d-flex flex-md-column gap-3 footer-brand "
                    >
                        <Logo />
                        <p>
                            {lang == "en"
                                ? "We are a professional platform. Here we will provide you only the interesting content, which you will like a lot. We are committed to providing you with the best horses, with an emphasis on reliability, buying and selling."
                                : "نحن منصة احترافية. سنقدم لك هنا المحتوى المثير للاهتمام فقط، والذي سيعجبك كثيرًا. نحن ملتزمون بتزويدك بأفضل الهجن مع التركيز على الاعتمادية والبيع والشراء."}
                        </p>
                    </Col>
                    <Col
                        xs="6"
                        lg="3"
                        className="footer-links pb-5 pb-lg-0 px-3"
                    >
                        <h4>{lang == "en" ? "Pages" : "الصفحات"}</h4>
                        <div>
                            {FOOTER_LINKS.map(({ text, to, icon, en }) => (
                                <Link
                                    key={text}
                                    className="d-flex flex-row flex-nowrap text-decoration-none footer-link py-2"
                                    to={to}
                                >
                                    <div className="px-2">{icon}</div>
                                    {lang == "en" ? en : text}
                                </Link>
                            ))}
                        </div>
                    </Col>
                    <Col
                        xs="6"
                        lg="3"
                        className="footer-links pb-5 pb-lg-0 px-3"
                    >
                        <h4>{lang == "en" ? "Services" : "خدماتنا"}</h4>
                        <div>
                            {SERVICES.map(({ text, to, en, icon }) => (
                                <Link
                                    key={text}
                                    className="d-flex flex-row flex-nowrap text-decoration-none footer-link py-2"
                                    to={to}
                                >
                                    <div className="px-2">{icon}</div>
                                    {lang == "en" ? en : text}
                                </Link>
                            ))}
                        </div>
                    </Col>
                    <Col
                        xs="12"
                        md="6"
                        lg="3"
                        className="footer-links px-md-0 px-3"
                    >
                        <h4>{lang == "en" ? "Contact" : "للتواصل"} </h4>
                        <div>
                            {lang == "en"
                                ? CONTACT.en.map(({ text, icon, to }) => (
                                      <Link
                                          key={text}
                                          className="d-block text-decoration-none footer-link py-2"
                                          to={to}
                                      >
                                          {icon} {text}
                                      </Link>
                                  ))
                                : CONTACT.ar.map(({ text, icon, to }) => (
                                      <Link
                                          key={text}
                                          className="d-block text-decoration-none footer-link py-2"
                                          to={to}
                                      >
                                          {icon} {text}
                                      </Link>
                                  ))}
                        </div>
                    </Col>
                </Row>
            </div>
            <Col className="justify-content-center d-flex gap-3 p-3">
                {data.result.map((social) => (
                    <a
                    key={social.name}
                        href={`${social.url?.startsWith("http") ? "" : "//"}${
                            social.url
                        } `}
                        target="_blank"
                    >
                        <div
                            className="overflow-hidden"
                            style={{
                                width: "30px",
                                height: "30px",
                                borderRadius: "500px",
                            }}
                        >
                            <img
                                className="w-100 h-100"
                                src={social.imgUrl}
                                alt={social.name}
                            />
                        </div>
                    </a>
                ))}
            </Col>
            <div
                className="copyright py-3"
                style={{ borderTop: "1px solid #fff" }}
            >
                <p className="m-0 text-center text-black-50">
                    {lang == "en"
                        ? "All rights reserved to Green Line "
                        : `جميع الحقوق محفوظة لجرين لاين `}{" "}
                    &copy;
                </p>
            </div>
        </footer>
    );
}
