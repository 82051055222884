import React, { useEffect, useState } from "react";
import "./BidDetail.css";
import { json, useParams } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import mainAxiosInstance from "../../config/axiosInstance";
import useAuthenticatedQuery from "../../hooks/useAuthenticatedQuery";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import Input from "../../components/Input/Input";
import toast from "react-hot-toast";
import arLocale from "date-fns/locale/ar";
import { format, parse } from "date-fns";
export function BidDetail() {
  const { lang } = useSelector((state) => state.lang);
  const { id } = useParams();
  let [LastBid, setLastBid] = useState(1);
  const { token, user } = useSelector((state) => state.user);
  const [update, setUpdate] = useState(true);
  const {
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    const updateData = {
      price: LastBid,
      biddingNo: itemsData.biddingNo,
      userId: user.id,
      title: itemsData.title,
      itemNo: itemsData.itemNo,
    };
    const res = await mainAxiosInstance.put(`api/bid/bidder`, updateData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (res.data.result === "Updated") {
      toast.success("تم تحديث السعر بنجاح", {
        position: "top-center",
        duration: 2000,
        style: {
          background: "#333",
          color: "#fff",
        },
      });
      setUpdate(!update);
    }
  };

  const { isLoading, data } = useAuthenticatedQuery({
    queryKey: ["bid", update],
    url: `api/bid/${id}`,
    config: {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  });
  useEffect(() => {
    if (data) {
      setLastBid(data.result.price + 1);
    }
  }, [data]);
  if (isLoading) return <Loader />;

  const itemsData = data.result;
  const ChangeBid = (num) => {
    if (num <= itemsData.price) return;
    else setLastBid(num);
  };
  const formattedDate = format(new Date(itemsData.endDate), "dd/MM/yyyy");
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className=" pt-4">
          <div
            className="card container py-0 my-2"
            style={{ border: "1px, solid #808080" }}
          >
            <div
              className="card-header bg-dark text-light row"
              style={{ borderRadius: "0px" }}
            >
              <div className="col-12 col-md-6">
                <h1 className="text-white">{itemsData.items.name}</h1>
              </div>
            </div>
            <div className="card-body">
              <div className="container rounded p-2">
                <div className="row">
                  <div className="col-12 col-lg-4 p-1 text-center">
                    <img
                      src={itemsData.items.photo[0]?.imgUrl}
                      style={{ borderRadius: "35px" }}
                      width="100%"
                      className="rounded"
                      alt={itemsData.items.name}
                    />
                  </div>
                  <div className="col-12 col-lg-8">
                    <div className="row pl-3">
                      <div className="col-12 d-flex justify-content-between align-items-center">
                        <h1>{itemsData.items.name}</h1>
                        <span className="badge bg-primary text-light p-2 border ">
                          {lang == "en" ? "Type" : "النوع"}:{" "}
                          {itemsData.items.type}
                        </span>
                      </div>
                      <div className="col-12 d-flex justify-content-between align-items-center">
                        <h1>{itemsData.items.name}</h1>
                        <span className="badge bg-danger text-light p-2 border ">
                          {lang == "en" ? "end Date" : "وقت الانتهاء"}:{" "}
                          {lang == "en"
                            ? format(new Date(itemsData.endDate), "dd/MM/yyyy")
                            : format(
                                new Date(itemsData.endDate),
                                "dd/MM/yyyy",
                                { locale: arLocale }
                              )}
                        </span>
                      </div>
                      <div className="col-12">
                        <p className="text-secondary pt-3">
                          {itemsData.items.description}
                        </p>
                      </div>
                    </div>
                    <div className="row mx-0">
                      <div className="item-list-2">
                        <div className="price">
                          <span className="text-style">
                            {lang == "en" ? "Last Bid" : "اخر المزيدات"}
                          </span>
                          <h3>{itemsData.price}$</h3>
                        </div>
                        <div className=" border-1 col-5">
                          <span className="text-style">
                            {" "}
                            {lang == "en" ? "Bid" : "ازيد"}
                          </span>
                          <div className="input-group bootstrap-touchspin bootstrap-touchspin-injected">
                            {user.id != itemsData.items.userId ? (
                              <>
                                <span className="input-group-btn input-group-prepend">
                                  <button
                                    className="btn btn-primary bootstrap-touchspin-down"
                                    type="button"
                                    onClick={() => ChangeBid(LastBid - +1)}
                                  >
                                    -
                                  </button>
                                </span>
                                <input
                                  style={{
                                    maxWidth: "100px",
                                  }}
                                  className="stepper form-control mx-2"
                                  type="number"
                                  value={LastBid}
                                  min={`${itemsData.price + 1}`}
                                  onChange={(ele) =>
                                    ChangeBid(+ele.target.value)
                                  }
                                  name="newPrice"
                                />
                                <span className="input-group-btn input-group-append">
                                  <button
                                    className="btn btn-primary bootstrap-touchspin-up"
                                    onClick={() => ChangeBid(LastBid + +1)}
                                    type="button"
                                  >
                                    +
                                  </button>
                                </span>
                              </>
                            ) : (
                              <h5 className="text-danger">
                                {lang == "en"
                                  ? "That's your item "
                                  : "هذا هو عنصرك"}
                              </h5>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer row bg-dark">
              <div className="col-12 col-md-6 pb-1 mx-auto">
                {user.id != itemsData.items.userId && (
                  <>
                    <button
                      className="btn btn-success btn-square form-control btn-lg"
                      href="/"
                      style={{ height: "50px" }}
                    >
                      {lang == "en" ? "Request Bid" : "طلب المزايدة"}
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
