import { Fragment, useState } from "react";
import "./Details.css";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { FaEdit } from "react-icons/fa";

function Details() {
    const { user } = useSelector((state) => state.user);
    const { lang } = useSelector((state) => state.lang);

    // State for editing password
    const [password] = useState("");

    // Handle password change
    // const handlePasswordChange = (e) => {
    //     setPassword(e.target.value);
    // };

    return (
        <Fragment>
            <h2>{lang === "en" ? "Personal Details" : "البيانات الشخصية"} :</h2>
            <div className="row px-lg-4 justify-content-start details">
                <div className="col-lg-12">
                    <h3 className="m-3 py-3 px-4 border rounded-pill">
                        {lang === "en" ? "Name" : "الاسم"} : {user.fullName}
                    </h3>
                </div>

                <div className="col-lg-12">
                    <h3 className="m-3 py-3 px-4 border rounded-pill">
                        {lang === "en" ? "Passport" : "رقم جواز السفر"} : {user.passportNumber}
                    </h3>
                </div>
                <div className="col-lg-12">
                    <h3 className="m-3 py-3 px-4 border rounded-pill">
                        {lang === "en" ? "Phone" : "رقم الهاتف"} : {user.phone}
                    </h3>
                </div>
                <div className="col-12">
                    <h3 className="m-3 py-3 px-4 border rounded-pill">
                        {lang === "en" ? "Email" : "البريد الالكتروني"} :{" "}
                        <a className="text-decoration-none text-black" href={`mailto:${user.email}`}>
                            {user.email}
                        </a>
                    </h3>
                </div>

                <div className="col-12">
                    <h3 className="m-3 py-3 px-4 border rounded-pill">
                        {lang === "en" ? "Password" : "كلمة المرور"}: 
                        <input
                            type="password"
                            value={sessionStorage.getItem("userPassword") || ""}
                            className="border-0 bg-transparent"
                            readOnly
                        />
                    </h3>
                </div>


                <div className="col-12 text-center mt-4">
                    <Link
                        to="/profile/editdetails"
                        className="btn btn-primary w-50 flex"
                    >
                        {lang === "en" ? "Edit Details" : "تعديل البيانات"} <FaEdit />
                    </Link>
                </div>
            </div>
        </Fragment>
    );
}

export default Details;
