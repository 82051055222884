import { Link, NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { USER, PROFILE_LINKS } from "../../data";
import "./ProfileNav.css";
import { useEffect, useState } from "react";
import { IoAddCircleSharp } from "react-icons/io5";

function ProfileNav() {
    const { user } = useSelector((state) => state.user);
    const { lang } = useSelector((state) => state.lang);
    const [userType, setUserType] = useState("");
    useEffect(() => {
        setUserType(user.actions.map(action => action.type.name).join(', '));
    }, [user, lang]);

    return (
        // <div className="d-flex flex-row-reverse">
        <div className="row align-content-center justify-content-center text-center gy-3">
            <div className="user-image-container rounded-pill overflow-hidden p-3">
                <img
                    className="w-100"
                    src={USER.profileImage}
                    alt={lang === "en" ? "Profile Image" : "صورة الملف الشخصي"}
                />
            </div>
            <h2 className="d-flex justify-content-center">
                {user.fullName}
                <span className="m-2 mt-0 p-2 badge bg-success w-auto fs-6">
                    {userType}
                </span>
            </h2>
            <div className="d-flex align-items-center mb-3 gap-2 justify-content-center">
                {/*lang === "en"
                    ? "You can Add Item here"
                    : "يمكنك اضافة عنصر جديد من هنا"
                */
                }
                <Link
                    to="/addItem"
                    className="btn btn-outline-primary "
                    style={{ width: "150px" }}
                >
                    {lang === "en" ? "Add Item" : "اضافة عنصر"}{" "}
                    <IoAddCircleSharp />{" "}
                </Link>
            </div>
            <div className="mt-0">
                <a
                    className="btn btn-outline-primary dropdown-toggle more-btn pt-2"
                    href="#"
                    role="button"
                    id="dropdownMenuLink"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                >
                    {lang === "en" ? "More" : "المزيد"}
                </a>
                <nav
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuLink"
                >
                    {PROFILE_LINKS.map(({ text, to, en, icon }) => (
                        <NavLink
                            key={text}
                            className="dropdown-item d-flex gap-1 "
                            to={to}
                        >
                            {icon}
                            {lang === "en" ? en : text}
                        </NavLink>
                    ))}
                </nav>
            </div>
        </div>
    );
}

export default ProfileNav;
