import { Outlet } from "react-router-dom";
import ProfileNav from "../../components/ProfileNav/ProfileNav";
import { useDispatch, useSelector } from "react-redux";
import useAuthenticatedQuery from "../../hooks/useAuthenticatedQuery";
import { addUserData } from "../../slices/userSlice";
import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import mainAxiosInstance from "../../config/axiosInstance";

function ProfileLayout() {
    const dispatch = useDispatch()
    const { token ,updates } = useSelector((store) => store.user);
    // const [data1 , setData] = useState(1)
    // const { data } = useAuthenticatedQuery(
    //     {
    //     queryKey: [updates],
    //     url: "api/user",
    //     config: {
    //         headers: {
    //             Authorization: `Bearer ${token}`,
    //         },
    //     },
    // });
    // if (data) {
    //     dispatch(addUserData(data?.result));
    // }
    
    return (
        <div className="container my-5 p-lg-5 profile-container rounded-5">
            <ProfileNav />
            <div className="pt-5">
                <Outlet />
            </div>
        </div>
    );
}

export default ProfileLayout;
