import { useForm, SubmitHandler } from "react-hook-form";
import { Fragment, useState } from "react";

import Input from "../../../components/Input/Input";
import Button from "../../../components/Button/Button";
import InputErrorMessage from "../../../components/InputErrorMessage/InputErrorMessage";

import { EDIT_DETAILS_INPUTS, USER } from "../../../data";
import { useDispatch, useSelector } from "react-redux";
import mainAxiosInstance from "../../../config/axiosInstance";
import useAuthenticatedQuery from "../../../hooks/useAuthenticatedQuery";
import Loader from "../../../components/Loader/Loader";
import { addUserData, updates } from "../../../slices/userSlice";
import { ImSpinner7 } from "react-icons/im";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

function EditDetails() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { user, token } = useSelector((state) => state.user);
    const { lang } = useSelector((state) => state.lang);
    const [updateUserLoader, setUpdateUserLoader] = useState(false);

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm();

    const onSubmit = async (data) => {
        try {
            setUpdateUserLoader(true);
            const update = {
                ...user,
                fullName: data.fullName,
                phone: data.phone,
                email: data.email,
                passportNumber: data.passportNumber,
                password: data.password
            };
            const response = await mainAxiosInstance.put("api/user", update, {
                headers: { Authorization: `Bearer ${token}` },
            });
            dispatch(addUserData({ ...update }));
            if (response.status === 200 ) {
                toast.success( lang === "en" ? "Details updated successfully" : "تم تحديث التفاصيل بنجاح", {
                    position: "top-center",
                    duration: 1500,
                    style: {
                        backgroundColor: "black",
                        color: "white",
                        width: "fit-content",
                    },
                });
                setTimeout(() => navigate("/profile"))
            }
        } catch (error) {
            console.log(error);
            toast.error(error)
        } finally {
            setUpdateUserLoader(false);
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            {EDIT_DETAILS_INPUTS.map(({ type, rules, en, ar, id }) => (
                <Fragment key={id}>
                    <div className="position-relative">
                        <Input
                            defaultValue={user[id]}
                            type={type}
                            {...register(id, rules)}
                            placeholder={
                                lang === "en" ? en.placeholder : ar.placeholder
                            }
                            error={errors[id] && true}
                        />
                    </div>

                    {errors[id] && (
                        <InputErrorMessage
                            msg={
                                lang === "en"
                                    ? en.validations[errors[id].type]
                                    : ar.validations[errors[id].type]
                            }
                        />
                    )}
                </Fragment>
            ))}
               {/* Password Field */}
               <Fragment>
                <div className="position-relative">
                    <Input
                        type="password"
                        {...register("password", {
                            required: lang === "en" 
                                ? "Password is required" 
                                : "كلمة المرور مطلوبة",
                            minLength: {
                                value: 6,
                                message: lang === "en"
                                    ? "Password must be at least 6 characters"
                                    : "كلمة المرور يجب أن تكون على الأقل 6 أحرف",
                            },
                        })}
                        placeholder={lang === "en" ? "Password" : "كلمة المرور"}
                        error={errors.password && true}
                    />
                </div>

                {errors.password && (
                    <InputErrorMessage
                        msg={errors.password.message}
                    />
                )}
            </Fragment>
            <Button width="100%" disabled={updateUserLoader}>
                {" "}
                {updateUserLoader ? (
                    <ImSpinner7 className="spinner" />
                ) : lang === "en" ? (
                    "Save Changes"
                ) : (
                    "حفظ التعديلات"
                )}
            </Button>
        </form>
    );
}

export default EditDetails;
