import { Link } from "react-router-dom";
import { Fragment, useEffect } from "react";

import "./Terms.css";
import { useSelector } from "react-redux";
import useAuthenticatedQuery from "../../hooks/useAuthenticatedQuery";
import Loader from "../../components/Loader/Loader";

function Terms() {
    const { lang } = useSelector((state) => state.lang);
    const { isLoading, data } = useAuthenticatedQuery({
        queryKey: ["terms_conditions"],
        url: "api/terms_conditions",
        config: {},
    });
    useEffect(() => {
        if (isLoading) return;
        const terms = new DOMParser().parseFromString(
            lang === "en" ? data.result.textEn : data.result.textAr,
            "text/html"
        );
        document.getElementById("terms").innerHTML = terms.body.innerHTML
    }, [data, lang]);
    if (isLoading) return <Loader />;
    return (
        <Fragment>
            <section className="terms-page page-title align-items-center justify-content-center d-flex">
                <h1 className="text-center fw-bold text-light">
                    {lang === "en" ? "Terms & Conditions" : "الشروط والاحكام"}
                </h1>
            </section>
            <section className="container py-5">
                <h2 className="fw-bold pt-3">
                    {lang === "en" ? "When you use the site, you are aware and committed to the following" : "عند إستخدامك للموقع فانت على علم و ملتزم بالآتي"} :
                </h2>
                <div id="terms"></div>
                {
                    /*<ul className="pt-5 fs-5 d-flex flex-column gap-3">
                    <li>
                        الموقع لا يقوم بالإعلان بشكل خاص ولا يبيع اي سلعة,انما
                        يقدم خدمة تُمكن المستخدم من الإعلان وادارة اعلاناته في
                        الموقع.
                    </li>
                    <li>
                        التعهد بالالتزام بتعاليم الشريعة الإسلامية والآداب
                        العامة في جميع مشاركاتي
                    </li>
                    <li>عدم استخدام اسم مستخدم غير لائق .</li>
                    <li>عدم الإعلان بأكثر من عضوية في الموقع .</li>
                    <li>
                        التأكد من صحة المعلومات أثناء التسجيل، مثل أرقام الاتصال
                        أو البريد الإلكتروني أو غير ذلك.
                    </li>
                    <li>
                        التقيد بأقسام الموقع، و وضع كل إعلان في قسمه الصحيح وعدم
                        اضافة روابط لمواقع أخرى
                    </li>
                    <li>
                        لالتزام بعدم كتابة طلبات أو عروض غير جادة أو نشر إعلانات
                        لا تتعلق بالبيع والشراء في غير اختصاص الموقع.
                    </li>
                    <li>عدم بخس إعلانات الأعضاء.</li>
                    <li>
                        عدم المزايدة على اعلان معين عن طريق استخدام اكثر من
                        عضوية.
                    </li>
                    <li>
                        سيتم حذف العضوية التي التي لم تستخدم لمدة سنة كاملة.
                    </li>
                    <li>
                        تتم العمليات التجارية بين الأعضاء مباشرة دون أي مسئولية
                        على الموقع , والموقع يُحذّر من تحويل الأموال وينصح
                        بالتعامل المباشر بين الطرفين.
                    </li>
                    <li>
                        تحصل إدارة الموقع على عمولة قدرها 1% من أي عملية تجارية
                        تتم عن طريق الموقع و يلتزم المعلن بسدادها، وتبقى أمانه
                        في ذمته ويحق لإدارة الموقع المطالبة به.
                    </li>
                    <li>يحق لإدارة الموقع تغير نسبة العمولة في اي وقت.</li>
                    <li>
                        يحق للموقع التحديث أو التغيير أو الإضافة أو الحذف لأي من
                        بنود هذه الاتفاقية.
                    </li>
                    <li>
                        قبولك لشروط اتفاقية استخدام الموقع يعني موافقتك والتزامك
                        بكل بنود هذه الاتفاقية , وأي إخلال لأحد هذه البنود فيحق
                        لإدارة الموقع اتخاذ العقوبة التي تراها مناسبة.
                    </li>
                    <li>
                        يتحمل العضو المسئولية القانونية كاملة أمام الجهات
                        الرسمية عن كل مشاركاته , دون ادني مسؤولية على الموقع.
                    </li>
               </ul>*/
            }

                <Link to="/register" className="btn btn-primary d-block w-50 mx-auto ">{lang === "en" ? "Register now" :"سجل أﻷن"}</Link>
            </section>
        </Fragment>
    );
}

export default Terms;
