import { IoMdClose } from "react-icons/io";
import useAuthenticatedQuery from "../../hooks/useAuthenticatedQuery";
import "./AdsSide.css";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SingleAd from "./SingleAd";
function AdsSide() {
    const { lang } = useSelector((state) => state.lang);
    const [currntAd, setCurrntAd] = useState({});
    const [currntAd2, setCurrntAd2] = useState({});
    const [currntAd3, setCurrntAd3] = useState({});

    const { isLoading, data } = useAuthenticatedQuery({
        queryKey: ["ads-Side"],
        url: "/api/ads/filterBytype?type=sidebar",
        config: {},
    });

    useEffect(() => {
        if (isLoading) return;
        let index = 0;
        setCurrntAd(data.result[index]);
        setCurrntAd2(data.result[index + 1]);
        setCurrntAd3(data.result[index + 2]);
        if (data.result.length > 3) {
            setInterval(() => {
                if (index >= data.result.length - 3) {
                    index = 0;
                    setCurrntAd(data.result[index]);
                    setCurrntAd2(data.result[index + 1]);
                    setCurrntAd3(data.result[index + 2]);
                } else {
                    index++;
                    setCurrntAd(data.result[index]);
                    setCurrntAd2(data.result[index + 1]);
                    setCurrntAd3(data.result[index + 2]);
                }
            }, 15000);
        }
    }, [data, isLoading]);

    if (isLoading) return;

    if (data.result.length === 0) return;

    return (
        <div
            className={`flex flex-col justify-start ads-side gap-5`}
            style={{
                marginTop: document.querySelector(".navbar")?.clientHeight,
                // height: `calc(100vh - ${
                //     document.querySelector(".navbar").clientHeight
                // }px)`,
                height : "auto",
            }}
        >
            <SingleAd ad={currntAd} />
            {currntAd2 && <SingleAd ad={currntAd2} />}
            {currntAd3 && <SingleAd ad={currntAd3} />}
        </div>
    );
}

export default AdsSide;
