import { createSlice } from '@reduxjs/toolkit'

const token = JSON.parse(localStorage.getItem('token')) || null

let initialState = {
  user : {},
  token:token,
  logedIn : false,
  updates : 0,
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    addUserData: (state , action) => {
      state.user = action.payload
      state.logedIn = true
    },
    addUserToken: (state , action) => {
      state.token = action.payload
      localStorage.setItem("token", JSON.stringify(action.payload));
    },
    logOut: () => {
      localStorage.removeItem("token");
      return {
        ...initialState,
        token: ""
      }
    },
    updates: (state) => {
      state.updates += 1
    }
  },
})

export const {addUserData ,addUserToken , logOut ,updates} = userSlice.actions

export default userSlice.reducer
