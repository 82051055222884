import {
    createBrowserRouter,
    createRoutesFromElements,
    Route,
} from "react-router-dom";
import { Navigate } from "react-router-dom"; // <-- Add this import

import MainLayout from "../pages/Layouts/MainLayout";
import ProfileLayout from "../pages/Layouts/ProfileLayout.jsx";
import Home from "../pages/Home/Home";
import About from "../pages/About/About";
import Contact from "../pages/Contact/Contact";
import Services from "../pages/Services/Services";
import Testimonials from "../pages/Testimonials/Testimonials";
import ItemDetails from "../pages/ItemDetails/ItemDetails";
import Register from "../pages/Register/Register";
import Login from "../pages/Login/Login";
import PageNotFound from "../pages/PageNotFound/PageNotFound";
import { Bid } from "../pages/Bid/Bid";
import { BidDetail } from "../pages/BidDetail/BidDetail";
import AddItem from "../pages/AddItem/AddItem.jsx";
import Details from "../pages/Profile/Details/Details";
import MyBids from "../pages/Profile/MyBids/MyBids";
import { Fragment } from "react";
import MyElemnts from "../pages/Profile/MyElemnts/MyElemnts.jsx";
import Terms from "../pages/Terms/Terms.jsx";
import PrivacyPolicy from "../pages/PrivacyPolicy/PrivacyPolicy.jsx";
import EditDetails from "../pages/Profile/EditDetails/EditDetails.jsx";
import ProtectedRoute from "../auth/ProtectedRoute.jsx";
import Payment from "../pages/Payment/Payment.jsx";
import FeedBack from "../pages/FeedBack/FeedBack.jsx";
import ContractingPolicy from "../pages/ContractingPolicy/ContractingPolicy.jsx";
import DeliveryPeriod from "../pages/DeliveryPeriod/DeliveryPeriod.jsx";
import RefundPolicy from "../pages/RefundPolicy/RefundPolicy.jsx";
import Trainers from "../pages/Trainers/Trainers.jsx";
import HowToBuy from "../pages/HowToBuy/HowToBuy.jsx";
import TrainerDetails from "../pages/TrainerDetails/TrainerDetails.jsx";
import AddTrainer from "../pages/AddTrainer/AddTrainer.jsx";
import PaymentDone from "../pages/PaymentDone/PaymentDone.jsx";
import { useSelector } from "react-redux";

const ProtectedRouteWrapper = ({ children, redirectPath }) => {
    const { token } = useSelector((state) => state.user); // Check if user is authenticated
    if (!token) {
        return <Navigate to={redirectPath} replace />;
    }
    return children;
};
const router = createBrowserRouter(
    createRoutesFromElements(
        <Fragment>
            <Route path="/" element={<MainLayout />}>
                <Route index element={<Home />} />
                <Route path="home" element={<Home />} />
                <Route path="about" element={<About />} />
                <Route path="contact" element={<Contact />} />
                <Route path="services" element={<Services />} />
                <Route path="testimonials" element={<Testimonials />} />
                <Route path="bidding" element={<Bid />} />
                <Route
                    path="details/:id"
                    element={
                        <ProtectedRouteWrapper                         
                         isAllowed={true}
                          redirectPath="/login"
                          data={{ from: "itemdetails" }}
                         >
                            <ItemDetails />
                        </ProtectedRouteWrapper>
                    }
                />                <Route path="contractingpolicy" element={<ContractingPolicy />} />
                <Route path="deliveryperiod" element={<DeliveryPeriod />} />
                <Route path="refundpolicy" element={<RefundPolicy />} />

                <Route
                    path="userjourney"
                    element={
                        <h2 className="text-center p-5 m-5">USER JOURNEY</h2>
                    }
                />
                <Route
                    path="feedback"
                    element={
                        <ProtectedRoute
                            isAllowed={true}
                            redirectPath="/login"
                            data={{ from: "additem" }}
                        >
                            <FeedBack />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="bidding/:id"
                    element={
                        <ProtectedRoute
                            isAllowed={true}
                            redirectPath="/login"
                            data={{ from: "additem" }}
                        >
                            <BidDetail />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="additem"
                    element={
                        <ProtectedRoute
                            isAllowed={true}
                            redirectPath="/login"
                            data={{ from: "additem" }}
                        >
                            <AddItem />
                        </ProtectedRoute>
                    }
                />
                <Route path="terms&conditions" element={<Terms />} />
                <Route path="howtobuy" element={<HowToBuy />} />
                <Route path="privacypolicy" element={<PrivacyPolicy />} />
                <Route
                    path="payment/:id"
                    element={
                        <ProtectedRoute
                            isAllowed={true}
                            redirectPath="/login"
                            data={{ from: "additem" }}
                        >
                            <Payment />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="trainers"
                    element={
                        <ProtectedRoute
                            isAllowed={true}
                            redirectPath="/login"
                            data={{ from: "trainers" }}
                        >
                            <Trainers />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="trainers/:id"
                    element={
                        <ProtectedRoute
                            isAllowed={true}
                            redirectPath="/login"
                            data={{ from: "trainers" }}
                        >
                            <TrainerDetails />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="addtrainer/:id"
                    element={
                        <ProtectedRoute
                            isAllowed={true}
                            redirectPath="/login"
                            data={{ from: "trainers" }}
                        >
                            <AddTrainer />
                        </ProtectedRoute>
                    }
                />
                
                <Route
                    path="profile"
                    element={
                        <ProtectedRoute
                            isAllowed={true}
                            redirectPath="/login"
                            data={{ from: "profile" }}
                        >
                            <ProfileLayout />
                        </ProtectedRoute>
                    }
                >
                    <Route index element={<Details />} />
                    <Route path="details" element={<Details />} />
                    <Route path="mybids" element={<MyBids />} />
                    <Route path="myelements" element={<MyElemnts />} />
                    <Route path="editdetails" element={<EditDetails />} />
                </Route>
            </Route>
            <Route
                path="register"
                element={
                    <ProtectedRoute
                        isAllowed={false}
                        redirectPath="/profile"
                        data={{ from: "register" }}
                    >
                        <Register />
                    </ProtectedRoute>
                }
            />

            <Route
                path="login"
                element={
                    <ProtectedRoute
                        isAllowed={false}
                        redirectPath="/"
                        data={{ from: "login" }}
                    >
                        <Login />
                    </ProtectedRoute>
                }
            />
            <Route
                    path="paymentdone/:id"
                    element={
                        <ProtectedRoute
                            isAllowed={true}
                            redirectPath="/login"
                            data={{ from: "trainers" }}
                        >
                            <PaymentDone />
                        </ProtectedRoute>
                    }
                />
            <Route path="*" element={<PageNotFound />} />
        </Fragment>
    )
);

export default router;
