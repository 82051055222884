import { createSlice } from '@reduxjs/toolkit'

const lang = JSON.parse(localStorage.getItem('lang')) || 'ar'
let initialState = {
  lang : lang
}

const langSlice = createSlice({
  name: 'lang',
  initialState,
  reducers: {
    swapLang: (state) => {
      state.lang === 'ar' ? state.lang = 'en' : state.lang = "ar"
      localStorage.setItem("lang", JSON.stringify(state.lang));
    },
  },
})



export const {swapLang} = langSlice.actions

export default langSlice.reducer
