import { IoMdClose } from "react-icons/io";
import useAuthenticatedQuery from "../../hooks/useAuthenticatedQuery";
import "./AdsBot.css";
import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
function AdsBot() {
    const { lang } = useSelector((state) => state.lang);
    const [currntAd, setCurrntAd] = useState({});
    const [currntAd2, setCurrntAd2] = useState({});
    const [hide, setHide] = useState(false);
    const [hide2, setHide2] = useState(false);
    const deleteAd = (fnc) => {
        fnc(true);
        setTimeout(() => fnc(false), 30000);
    };
    const { isLoading, data } = useAuthenticatedQuery({
        queryKey: ["ads-bot"],
        url: "/api/ads/filterBytype?type=bottom",
        config: {},
    });

    useEffect(() => {
        if (isLoading) return;
        let index = 0;
        setCurrntAd(data.result[index]);
        setCurrntAd2(data.result[index + 1]);
        if (data.result.length > 2) {
            setInterval(() => {
                if (index >= data.result.length - 2) {
                    index = 0;
                    setCurrntAd(data.result[index]);
                    setCurrntAd2(data.result[index + 1]);
                } else {
                    index++;
                    setCurrntAd(data.result[index]);
                    setCurrntAd2(data.result[index + 1]);
                }
            }, 15000);
        }
    }, [data, isLoading]);

    if (isLoading) return;

    if (data.result.length === 0) return;

    return (
        <div className="d-flex ads-bot-container">
            <div className={`ads-bot end-50 p-1 ${hide ? "d-none" : ""}`}>
                <a
                    className="text-decoration-none text-white d-block w-100 h-100 text-center"
                    href={currntAd?.link}
                >
                    <p className="ads-text fw-bold position-absolute top-50 start-50 translate-middle">
                        {currntAd.text}
                    </p>
                    <div
                        className="position-relative mx-auto p-1 overflow-hidden"
                        style={{ maxWidth: "fit-content", maxHeight: "100%" }}
                    >
                        <button
                            onClick={() => deleteAd(setHide)}
                            className="btn position-absolute  p-0  translate-middle"
                            style={{
                                top: "10px",
                                left: "10px",
                                background: "#fff9",
                                width: "20px",
                                height: "20px",
                                zIndex: "1000000000",
                            }}
                        >
                            <IoMdClose />
                        </button>
                        <div className="overlay d-flex justify-content-center align-items-center">
                            <p className="ads-text fw-bold ">
                                {currntAd2?.text}
                            </p>
                        </div>{" "}
                        <img
                            style={{ maxWidth: "300px", maxHeight: "100%" }}
                            className="w-100 h-100 mx-auto"
                            src={currntAd?.adsImgUrl}
                            alt={currntAd?.text}
                        />
                    </div>
                </a>
            </div>
            <div
                className={`ads-bot start-50 mx-2 p-1 text-center ${
                    hide2 ? "d-none" : ""
                }`}
            >
                <a
                    className="text-decoration-none text-white d-block w-100 h-100"
                    href={currntAd2?.link}
                >
                    <div
                        className="position-relative mx-auto p-1 overflow-hidden"
                        style={{ maxWidth: "fit-content", maxHeight: "100%" }}
                    >
                        <button
                            onClick={() => deleteAd(setHide2)}
                            className="btn position-absolute p-0  translate-middle"
                            style={{
                                top: "10px",
                                left: "10px",
                                background: "#fff9",
                                width: "20px",
                                height: "20px",
                                zIndex: "1000000000",
                            }}
                        >
                            <IoMdClose />
                        </button>
                        <div className="overlay d-flex justify-content-center align-items-center">
                            <p className="ads-text fw-bold ">
                                {currntAd2?.text}
                            </p>
                        </div>
                        <img
                            style={{ maxWidth: "300px" }}
                            className="w-100 h-100"
                            src={currntAd2?.adsImgUrl}
                            alt={currntAd2?.text}
                        />
                    </div>
                </a>
            </div>
        </div>
    );
}

export default AdsBot;
