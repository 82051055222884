import { useSelector } from "react-redux";
import "./ItemCard.css";
import { Fragment } from "react";
import { MdDelete } from "react-icons/md";
import { IoMdAddCircle } from "react-icons/io";

function ItemCard({
  item,
  bid,
  slider,
  edits,
  setCurrentItemId,
  deleteErrors,
  biddingNo,
}) {
  const { lang } = useSelector((state) => state.lang);
  
  return (
    <div
      className={`item-container ${
        slider ? "mx-auto mb-5" : "col-lg-3 col-md-4 col-sm-6"
      }`}
    >
      <a
        className="text-decoration-none text-center"
        href={`/${bid ? "bidding" : "details"}/${
          biddingNo != undefined && biddingNo != null ? biddingNo : item.id
        }`}
      >
        <div className="inner-box">
          <div className="content p-3 pb-0">
            <img
              className="content-image w-100"
              src={item?.photo[0]?.imgUrl}
              alt={item.name}
            />
          </div>
          <div></div>
          <div className="items-section-content">
            <h4 className="text-capitalize m-2 overflow-hidden">{item.name}</h4>
            {bid ? (
              <h5>
                {lang == "ar" ? "اخر مزايدة" : "Last Bid"}

                <span className="price-box px-1 position-relative d-inline-block">
                  {item.price} {item.currency}
                </span>
              </h5>
            ) : (
              <h5>
                {lang == "ar" ? "السعر" : "Price"}

                <span className="d-inline-block price-box px-1 position-relative">
                  {item.price} {item.currency}
                </span>
              </h5>
            )}
            <p className="text-capitalize m-2">{item.history}</p>
            {edits ? (
              <Fragment>
              <button
                    className="w-100 btn btn-primary m-2 mx-auto d-flex gap-1 justify-content-center"
                    data-bs-toggle="modal"
                    data-bs-target="#addTrainer"
                    onClick={(e) => {
                      e.preventDefault();
                      setCurrentItemId(item.id);
                    }}
                  >
                  <IoMdAddCircle />
                    {lang === "en" ? "Add Trainer" : "اضافة مدرب"}
                  </button>
                {bid ? (
                  <button
                    className="w-100 btn btn-primary m-2 mx-auto "
                    data-bs-toggle="modal"
                    data-bs-target="#deleteBid"
                    id=""
                    onClick={(e) => {
                      e.preventDefault();
                      setCurrentItemId(biddingNo);
                    }}
                  >
                  <MdDelete />
                    {lang === "en" ? "delete from Bids" : "حذف من المزايدة"}
                  </button>
                ) : (
                  
                  <button
                    className="w-100 btn btn-primary m-2 mx-auto d-flex gap-1 justify-content-center"
                    data-bs-toggle="modal"
                    data-bs-target="#addToBidModal"
                    onClick={(e) => {
                      e.preventDefault();
                      deleteErrors(item.id);
                      setCurrentItemId(item.id);
                    }}
                  >
                  <IoMdAddCircle />
                    {lang === "en" ? "Add To Bids" : "اضافة للمزايدة"}
                  </button>
                )}
                {bid ? null : (
                  <button
                    className="btn btn-danger m-2 mx-auto w-100 "
                    data-bs-toggle="modal"
                    data-bs-target="#confirmDeleteItemModal"
                    id=""
                    onClick={(e) => {
                      setCurrentItemId(item.id);
                      e.preventDefault();
                    }}
                  >
                    <MdDelete />{" "}
                    {lang === "en" ? "Delete" : "حذف"}
                  </button>
                )}
              </Fragment>
            ) : (
              <a
                className="w-100 btn btn-primary m-2 mx-auto"
                href={`/${bid ? "bidding" : "details"}/${
                  biddingNo != undefined && biddingNo != null
                    ? biddingNo
                    : item.id
                }`}
              >
                {lang == "en" ? "More" : "المزيد"}
              </a>
            )}
          </div>
        </div>
      </a>
    </div>
  );
}

export default ItemCard;
