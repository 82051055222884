import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import useAuthenticatedQuery from "../../hooks/useAuthenticatedQuery";
import Loader from "../../components/Loader/Loader";
import "./Payment.css";
import { Fragment } from "react";
import mainAxiosInstance from "../../config/axiosInstance";
import Input from "../../components/Input/Input";
import toast from "react-hot-toast";
import { PAYMENT_INPUTS } from "../../data";
import { useForm } from "react-hook-form";
import InputErrorMessage from "../../components/InputErrorMessage/InputErrorMessage";
import PaymentForm from "../../components/PaymentForm/PaymentForm";
function Payment() {
  const { lang } = useSelector((state) => state.lang);
  const { user } = useSelector((state) => state.user);
  const { token } = useSelector((state) => state.user);
  const { id } = useParams();

  const onSubmit = async (data) => {
    const PaymentData = {
      userId: user.id,
      firstName: data.firstName,
      lastName: data.lastName,
      PhoneNo: data.phone,
      emailAddress: data.email,
      itemId: id,
    };
    const res = await mainAxiosInstance.post(`api/Payment`, PaymentData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (res.data.result) {
      console.log(res.data.result);
      toast.success("تم تحديث السعر بنجاح", {
        position: "top-center",
        duration: 2000,
        style: {
          background: "#333",
          color: "#fff",
        },
      });

      const payment=  await mainAxiosInstance.post(res.data.result.payUrl, res.data.result.transaction, {
        headers: {
          Authorization: res.data.result.authorization,
        },
      });
      console.log(payment.data)
    }
  };

  const { isLoading, data, error } = useAuthenticatedQuery({
    queryKey: [`itemPayment${id}`],
    url: `api/item/${id}`,
    config: {},
  });
  if (isLoading) return <Loader />;

  return (
    <div className="payment-page">
      <section className="page-title">
        <div className="overlay1"></div>
        <div className="container">
          <div className="title-box text-center align-items-center justify-content-center d-flex">
            <h1 className="text-center">
              {" "}
              {lang === "en" ? "payment details" : "تفاصيل الدفع"}
            </h1>
          </div>
        </div>
      </section>
      <section className="container py-5 d-flex flex-column flex-lg-row align-items-center align-items-lg-start gap-5">
        <div
          className="d-flex flex-column"
          style={{ maxWidth: "400px", minWidth: "300px" }}
        >
          <h3 className="text-center p-3">
            {lang === "en" ? "item details" : "تفاصيل العنصر "}
          </h3>
          <div className="item-details card d-flex">
            <div className="image-box">
              <img
                src={
                  data.result.photo.filter((item) => item.isMain === true)[0]
                    .imgUrl
                }
                alt="data.result.name"
                className="w-100"
              />
            </div>
            <div className="d-flex justify-content-center pt-3">
              <h5>{lang === "en" ? "Name" : "الاسم"} : </h5>
              <h3>{data.result.name}</h3>
            </div>
            <div className="d-flex justify-content-center pt-3">
              <h5>{lang === "en" ? "Price" : "السعر"} : </h5>
              <h3>
                {data.result.price} {data.result.currency}
              </h3>
            </div>
          </div>
        </div>
        <PaymentForm onSubmit={onSubmit}/>
      </section>
    </div>
  );
}

export default Payment;
