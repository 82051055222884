import React from "react";
import "./Testimonials.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { useSelector } from "react-redux";
import useAuthenticatedQuery from "../../hooks/useAuthenticatedQuery";
import Loader from "../../components/Loader/Loader";

var settings = {
  dots: true,
  infinite: true,
  centerMode: true,
  slidesToShow: 2,
  slidesToScroll: 1,
  initialSlide: 6,
  autoplay: true,
  speed: 500,
  autoplaySpeed: 2000,
  arrows: false,
  responsive: [
    {
      breakpoint: 1400,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

export default function Testimonials() {
  const { lang } = useSelector((state) => state.lang);

  const {data , isLoading } = useAuthenticatedQuery({
    queryKey: ["feedback"],
    url: "api/feedback",
    config: {},
  })

  if (isLoading) {
    return <Loader />
  }
  
  console.log(data.result)
  return (
    <section className="testimonials">
      <div className="page-title title-box text-center align-items-center justify-content-center d-flex">
        <h2 className="text-center text-light fw-bold">اراء العملاء</h2>
      </div>
      <div className="testmonial-data container mb-5 pt-5">
        <Slider {...settings}>
          {data.result.map((item) => (
            <div className="card p-3" key={item.user.fullName}>
              <div className="content d-flex justify-content-center flex-column align-items-center pt-4 gap-3">
                <h4 className="text-xl fw-bold">
                  {item.user.fullName}
                </h4>
                <p className="text-center">
                  {item.text}
                </p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
}
