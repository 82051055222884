import { useState } from "react";
import { IoMdClose } from "react-icons/io";
import { useSelector } from "react-redux";

function SingleAd({ ad }) {
    const { lang } = useSelector((state) => state.lang);
    const [hide, setHide] = useState(false);
    const deleteAd = () => {
        setHide(true);
        setTimeout(() => setHide(false), 30000);
    };

    return (
        <div
            className={` position-relative m-2 p-1 text-center ${
                hide ? "d-none" : ""
            }`}
        >
            <button
                onClick={() => deleteAd()}
                className="btn position-absolute p-0 translate-middle"
                style={{
                    top: "10px",
                    left: "10px",
                    background: "#fff9",
                    width: "20px",
                    height: "20px",
                    zIndex: "1000000000"
                }}
            >
                <IoMdClose />
            </button>
            <a
                className="text-decoration-none text-white d-block w-100 h-100"
                href={ad?.link}
            >
                <div className="overlay"></div>
                <p className="ads-text fw-bold position-absolute top-50 start-50 translate-middle">
                    {ad?.text}
                </p>
                <img
                    style={{ maxWidth: "300px" }}
                    className="w-100 h-100"
                    src={ad?.adsImgUrl}
                    alt={ad?.text}
                />
            </a>
        </div>
    );
}

export default SingleAd;
