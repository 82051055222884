import './ServiceCard.css'

function ServiceCard({icon , title, description}) {
    return (
        <div className="flip-container">
            <div className="flipper">
                <div className="front d-flex justify-content-center align-items-center flex-column gap-3 p-3">
                    <div className="service-icon">
                        {icon}
                    </div>
                    <div className="service-meta">
                        <h2 className="service-title fs-2  text-center">{title}</h2>
                    </div>
                </div>
                <div className="back d-flex justify-content-center align-items-center p-3 fs-5 text-center">
                    <p className="service-text ">
                        {description}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default ServiceCard
