import { useParams } from "react-router-dom";
import TrainerPayment from "../../components/TrainerPayment/TrainerPayment";
import { useSelector } from "react-redux";
import PaymentForm from "../../components/PaymentForm/PaymentForm";
import mainAxiosInstance from "../../config/axiosInstance";
import toast from "react-hot-toast";

function AddTrainer() {
    const { lang } = useSelector((stat) => stat.lang);
    const { user , token } = useSelector((stat) => stat.user );
    const { id } = useParams();
    const onSubmit = async (data) => {
        const PaymentData = {
          userId: user.id,
          firstName: data.firstName,
          lastName: data.lastName,
          PhoneNo: data.phone,
          emailAddress: data.email,
          itemId: id.split("&")[1],
          trnId: id.split("&")[0],
        };
        const res = await mainAxiosInstance.post(`api/Payment`, PaymentData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (res.data.result) {
          console.log(res.data.result);
          toast.success("تم ارسال الطلب بنجاح", {
            position: "top-center",
            duration: 2000,
            style: {
              background: "#333",
              color: "#fff",
            },
          });
    
          const payment=  await mainAxiosInstance.post(res.data.result.payUrl, res.data.result.transaction, {
            headers: {
              Authorization: res.data.result.authorization,
            },
          });
          console.log(payment.data)
        }
      };
    
    return (
        <div className="payment-page">
            <section className="page-title">
                <div className="overlay1"></div>
                <div className="container">
                    <div className="title-box text-center align-items-center justify-content-center d-flex">
                        <h1 className="text-center">
                            {" "}
                            {lang === "en" ? "payment details" : "تفاصيل الدفع"}
                        </h1>
                    </div>
                </div>
            </section>
            <section className="container py-5 d-flex flex-column flex-lg-row align-items-center align-items-lg-start gap-5">
                <TrainerPayment id={id.split("&")[0]} />
                <PaymentForm onSubmit={onSubmit}/>
            </section>
        </div>
    );
}

export default AddTrainer;
