import { useQuery } from "@tanstack/react-query";
import mainAxiosInstance from "../config/axiosInstance";

const useAuthenticatedQuery = ({
    url,
    queryKey,
    config,

}) => {
    return useQuery({
        queryKey,
        queryFn: async () => {
            const { data } = await mainAxiosInstance.get(url, config);
            return data;
        },
    },
      );
};
export default useAuthenticatedQuery;
