import './Button.css'

function Button({children,width ,disabled}) {
    return (
        <button className="btn btn-primary fw-bold text-shadow py-2 px-4 lh-md fs-5 rounded-pill" style={{
            width: width
        }}
        disabled={disabled}>
            {children}
        </button>
    )
}

export default Button

