import React, { useEffect, useState } from "react";
import "./Home.css";
import Pagination from "../../components/Pagination/Pagination";
import FilterButton from "../../components/FilterButton/FilterButton";
import ItemCard from "../../components/ItemCard/ItemCard";
import axiosInstance from "../../config/axsios.config";
import Loader from "../../components/Loader/Loader";
import mainAxiosInstance from "../../config/axiosInstance";
import { useSelector } from "react-redux";

function Items() {
  const { lang } = useSelector((state) => state.lang);
  const [allAnimals, setAllAnimals] = useState([]);
  const [animalsData, setAnimalsData] = useState([]);
  const [animalsTypes, setAnimalsTypes] = useState([]);
  const [active, setActive] = useState("all");
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(8);
  const [isLoading, setIsLoading] = useState(true);

  const fetchAnimalsData = async () => {
    try {
      const { data } = await mainAxiosInstance.get("api/item/active");
      const types = [...new Set(data.result.map((animal) => animal.type))];
      setAllAnimals(data.result);
      setAnimalsData(data.result);
      setAnimalsTypes(types);
    } catch (error) {
      console.error("Error fetching animal data:", error);
    } finally {
      setTimeout(() => setIsLoading(false), 500);
    }
  };

  useEffect(() => {
    fetchAnimalsData();
  }, []);

  const handleFilter = (type) => {
    if (type === active) return;
    setAnimalsData(
      type === "all"
        ? allAnimals
        : allAnimals.filter((animal) => animal.type === type)
    );
    setActive(type);
    setCurrentPage(1);
  };

  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const currentPosts = animalsData.slice(firstPostIndex, lastPostIndex);

  if (isLoading) return <Loader />;

  return (
    <section className="items-page" style={{ marginTop: "100px" }}>
      <div className="btns mt-4 mb-4 d-flex justify-content-center flex-wrap">
        <FilterButton
          isActive={active === "all"}
          onClick={() => handleFilter("all")}
        >
          {lang === "en" ? "All" : "الجميع"}
        </FilterButton>
        {animalsTypes.map((type) => (
          <FilterButton
            key={type}
            isActive={active === type}
            onClick={() => handleFilter(type)}
          >
            {type}
          </FilterButton>
        ))}
      </div>
      <div className="items-section">
        <div className="container">
          <div className="items-section-blog row">
            {currentPosts.map((item) => (
              <ItemCard key={item.id} item={item}></ItemCard>
            ))}
          </div>
          <Pagination
            totalPosts={animalsData.length}
            postsPerPage={postsPerPage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </div>
    </section>
  );
}

export default Items;
