import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ isAllowed, redirectPath, children, data }) => {
  const {logedIn} = useSelector(state => state.user)
  if (logedIn !== isAllowed) return <Navigate to={redirectPath} replace state={data} />;
  return children;
};

export default ProtectedRoute;
