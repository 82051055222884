import './FilterButton.css'

function FilterButton({ onClick , children , isActive }) {
    return (
        <button
            onClick={onClick}
            className={`btn mt-3 fw-bold mx-3 pt-2 ${isActive ? 'btn-primary' : "btn-outline-primary"}`} 
        >
            {children}
        </button>
    )
}

export default FilterButton
