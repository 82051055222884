import { Fragment } from "react";
import { BiDollar } from "react-icons/bi";
import { TbListDetails } from "react-icons/tb";
import { GiReceiveMoney } from "react-icons/gi";

const EMAIL_INPUT = {
    type: "email",
    id: "email",
    rules: {
        required: true,
        pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
    },
    ar: {
        placeholder: "البريد الالكتروني",
        validations: {
            required: "هذا الحقل مطلوب",
            pattern: "من فضلك قم بادخال بريد الاكتروني صالح",
        },
    },
    en: {
        placeholder: "Email",
        validations: {
            required: "This field is required",
            pattern: "Please enter a valid email address",
        },
    },
};

const PASSWORD_INPUT = {
    type: "password",
    id: "password",
    rules: {
        required: true,
        pattern:
            /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/,
    },
    ar: {
        placeholder: "الرقم السري",
        validations: {
            required: "هذا الحقل مطلوب",
            pattern: (
                <Fragment>
                    من فضلك يجب ان يحتوي الرقم السري على
                    <ul>
                        <li>حروف كبيرة</li>
                        <li>حروف صغيرة</li>
                        <li>رقم</li>
                        <li>@$!%*?&</li>
                        <li>8 احرف على الاقل</li>
                    </ul>
                </Fragment>
            ),
        },
    },
    en: {
        placeholder: "Password",
        validations: {
            required: "This field is required",
            pattern: (
                <Fragment>
                    Please enter a password that contains
                    <ul>
                        <li>Uppercase letters</li>
                        <li>Lowercase letters</li>
                        <li>Numbers</li>
                        <li>@$!%*?&#</li>
                        <li>At least 8 characters</li>
                    </ul>
                </Fragment>
            ),
        },
    },
};

const PHONE_INPUT = {
    type: "phone",
    id: "phone",
    rules: {
        required: true,
        pattern: /^0\d{0,14}$/,
    },
    ar: {
        placeholder: "رقم الهاتف",
        validations: {
            required: "هذا الحقل مطلوب",
            pattern:
                " من فضلك قم بادخال رقم هاتف صالح ،يكون بدايته 0 و اكثر حد من الارقام 15",
        },
    },
    en: {
        placeholder: "Phone Number",
        validations: {
            required: "This field is required",
            pattern:
                "Please enter a valid phone number, start with 0 , max number is 15 number",
        },
    },
};

const NAME_INPUT = {
    type: "text",
    id: "name",
    rules: {
        required: true,
        minLength: 4,
        maxLength: 30,
    },
    en: {
        placeholder: "Name",
        validations: {
            required: "This field is required",
            minLength: "Please enter a name between 4 and 30 characters",
            maxLength: "Please enter a name between 4 and 30 characters",
        },
    },
    ar: {
        placeholder: "الاسم ",
        validations: {
            required: "هذا الحقل مطلوب",
            minLength: "من فضلك قم بادخال اسم ما بين 4 و 15 حرف",
            maxLength: "من فضلك قم بادخال اسم ما بين 4 و 15 حرف",
        },
    },
};
export const LOGIN_INPUTS = [EMAIL_INPUT, PASSWORD_INPUT];

export const REGISTER_INPUTS = [
    NAME_INPUT,
    EMAIL_INPUT,
    PHONE_INPUT,
    {
        type: "text",
        id: "passportNumber",
        rules: {
            required: true,
            pattern: /^[A-Z0-9]{6,20}$/,
        },
        en: {
            placeholder: "Passport Number",
            validations: {
                required: "This field is required",
                pattern: "Please enter a valid passport number",
            },
        },
        ar: {
            placeholder: "رقم جواز السفر",
            validations: {
                required: "هذا الحقل مطلوب",
                pattern: "من فضلك قم بادخال رقم جواز سفر صالح",
            },
        },
    },
    PASSWORD_INPUT,
];

export const SERVICESCARDS = [
    {
        icon: (
            <svg
                stroke="currentColor"
                fill="none"
                strokeWidth="2"
                viewBox="0 0 24 24"
                strokeLinecap="round"
                strokeLinejoin="round"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M2 16.1A5 5 0 0 1 5.9 20M2 12.05A9 9 0 0 1 9.95 20M2 8V6a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2h-6"></path>
                <line x1="2" y1="20" x2="2" y2="20"></line>
            </svg>
        ),
        en: {
            title: "Training",
            description:
                "No worries when it comes to our cars. We provide you with the best training. We will teach you the basics of driving and control.",
        },
        ar: {
            title: "تدريب",
            description:
                "لا تحزن ولا تخاف فمعنا سيصبح الهجن صديقك الأول حتى تكون فارسه المفضّل وستتعلم أساسيات التعامل معه في فن الركوب والتحكم به",
        },
    },
    {
        icon: (
            <svg
                stroke="currentColor"
                fill="none"
                strokeWidth="2"
                viewBox="0 0 24 24"
                strokeLinecap="round"
                strokeLinejoin="round"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
            >
                <polygon points="12 2 2 7 12 12 22 7 12 2"></polygon>
                <polyline points="2 17 12 22 22 17"></polyline>
                <polyline points="2 12 12 17 22 12"></polyline>
            </svg>
        ),
        en: {
            title: "Equestrian Awareness Programs",
            description:
                "We seek to raise awareness among the equestrian community and our clients by holding some lectures and workshops specialized in the field of equestrianism by hosting some distinguished figures locally and regionally in the equestrian world on a regular basis.",
        },
        ar: {
            title: "برامج التوعية الفروسية",
            description:
                "نسعى لرفع سقف الوعي بالوسط الفروسي وعملائنا لعقد بعض المحاضرات وورش العمل المتخصصة بمجال الفروسيه وذلك باستضافة بعض الشخصيات المتميزة محلياً وإقليمياً في عالم الفروسية وبشكل دوري",
        },
    },
    {
        icon: (
            <svg
                stroke="currentColor"
                fill="none"
                strokeWidth="2"
                viewBox="0 0 24 24"
                strokeLinecap="round"
                strokeLinejoin="round"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                <circle cx="9" cy="7" r="4"></circle>
                <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
            </svg>
        ),
        en: {
            title: "Horse riding for beginners",
            description:
                "We have the most skilled trainers from the beginning until you control the horse and are skilled at crossing obstacles.",
        },
        ar: {
            title: "ركوب الهجن للمبتدئين",
            description:
                "لدينا أمهر المدربين من بدايتك حتى تقوم بالتحكم بالهجن وبمهارة تخطي الحواجز",
        },
    },
    {
        icon: (
            <svg
                stroke="currentColor"
                fill="none"
                strokeWidth="2"
                viewBox="0 0 24 24"
                strokeLinecap="round"
                strokeLinejoin="round"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect x="2" y="3" width="20" height="14" rx="2" ry="2"></rect>
                <line x1="8" y1="21" x2="16" y2="21"></line>
                <line x1="12" y1="17" x2="12" y2="21"></line>
            </svg>
        ),
        en: {
            title: "Horse shelter program",
            description:
                "We care for the thoroughbred as it deserves because we believe that whoever gives it to her will be his loyal friend. The doctor follows her condition carefully. We groom your horses professionally and take care of their health throughout the day.",
        },
        ar: {
            title: "برنامج إيواء الهجن",
            description:
                "نحن نرعى الهجن بما يستحق لأننا نؤمن بأن من يعطيها تكون صديقته المخلصة فالطبيب يتابع حالتها باهتمام ونحذو الهجن باحتراف ونرعى صحتها طوال اليوم",
        },
    },
    {
        icon: (
            <svg
                stroke="currentColor"
                fill="none"
                strokeWidth="2"
                viewBox="0 0 24 24"
                strokeLinecap="round"
                strokeLinejoin="round"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                <circle cx="9" cy="7" r="4"></circle>
                <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
            </svg>
        ),
        en: {
            title: "Horse driving and behavior modification program",
            description:
                "We are pleased to provide the service of operating and training horses for jumping or riding horses under the supervision of our professional trainers to correct or teach any wrong behavior.",
        },
        ar: {
            title: "برنامج تشغيل الهجن وتعديل السلوك",
            description:
                "يسعدنا تقديم خدمة تشغيل وتدريب الهجن للقفز او خيل الركوب باشراف مدربينا المحترفين لتعديل اي سلوك خاطئ او تعليمها",
        },
    },
    {
        icon: (
            <svg
                stroke="currentColor"
                fill="none"
                strokeWidth="2"
                viewBox="0 0 24 24"
                strokeLinecap="round"
                strokeLinejoin="round"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect x="2" y="3" width="20" height="14" rx="2" ry="2"></rect>
                <line x1="8" y1="21" x2="16" y2="21"></line>
                <line x1="12" y1="17" x2="12" y2="21"></line>
            </svg>
        ),
        en: {
            title: "Social responsibility and partnership",
            description:
                "Aware of our humanitarian role towards our society and nation, we are honored to welcome some groups dear to us and provide some support to them, such as people of determination, people with special needs, and children of martyrs, and to participate in national holidays and events as available, provided that this does not conflict with our goals and the public interest.",
        },
        ar: {
            title: "المسؤليه والشراكة الاجتماعيه",
            description:
                "استشعاراً منا بدورنا الانساني اتجاه مجتمعنا ووطنا نتشرف بالترحيب ببعض الفئات الغاليه علينا وتقديم بعض الدعم لهم مثل ذو الهمم، وذوي الاحتياجات الخاصه وابناء الشهداء والمشاركة بالاعياد والمناسبات الوطنية حسب المتاح على الا يتعارض ذلك مع اهدافنا والمصلحه العامه",
        },
    },
];

export const ADD_BID = [
    {
        type: "text",
        id: "name",
        rules: {
            required: true,
            minLength: 4,
            maxLength: 15,
        },
        ar : {
            placeholder: "الاسم",
            validations: {
                required: "هذا الحقل مطلوب",
                minLength: "من فضلك قم بادخال اسم ما بين 4 و 15 حرف",
                maxLength: "من فضلك قم بادخال اسم ما بين 4 و 15 حرف",
            },
        },
        en : {
            placeholder: "Name",
            validations: {
                required: "this field is required",
                minLength: "please enter a valid name between 4 and 15", 
                maxLength: "please enter a valid name between 4 and 15", 
            },
        }
    },
    {
        type: "text",
        id: "type",
        rules: {
            required: true,
            minLength: 3,
        },
        ar : {placeholder: "النوع",
        validations: {
            required: "من فضلك قم بادخال نوع الحيوان",
            minLength: "من فضلك قم بادخال نوع حيوان صحيح",
        },},
        en : {placeholder: "type",
        validations: {
            required: "this field is required",
            minLength: "pleas enter a valid type",
        },}
    },
    {
        type: "text",
        id: "history",
        rules: {
            required: true,
        },
        ar : {
        placeholder: "التاريخ",
        validations: {
            required: "هذا الحقل مطلوب",
        },},
        en : {
        placeholder: "History",
        validations: {
            required: "this field is required",
        },}
    },
    {
        type: "text",
        id: "foot",
        rules: {
            required: true,
        },
        ar : {

            placeholder: "وصف قدم الحيوان",
            validations: {
                required: "هذا الحقل مطلوب",
            }
        },
        en : {
            placeholder: "Animal foot image",
            validations: {
                required: "this field is required",
            }
        }
    },
    {
        type: "text",
        id: "eye",
        rules: {
            required: true,
        },
        ar : {

            placeholder: "وصف عين الحيوان",
            validations: {
                required: "هذا الحقل مطلوب",
            }
        },
        en : {
            placeholder: "Animal eye image",
            validations: {
                required: "this field is required",
            }
        }
    },
    {
        type: "text",
        id: "back",
        rules: {
            required: true,
        },
        ar : {

            placeholder: "وصف الحيوان من الخلف",
            validations: {
                required: "هذا الحقل مطلوب",
            }
        },
        en : {
            placeholder: "Animal back image",
            validations: {
                required: "this field is required",
            }
        }
    },
    {
        type: "text",
        id: "front",
        rules: {
            required: true,
        },
        ar : {

            placeholder: "وصف الحيوان من الأمام",
            validations: {
                required: "هذا الحقل مطلوب",
            }
        },
        en : {
            placeholder: "Animal front image",
            validations: {
                required: "this field is required",
            }
        }
    },
    {
        type: "text",
        id: "body",
        rules: {
            required: true,
        },
        ar : {

            placeholder: "وصف جسم الحيوان",
            validations: {
                required: "هذا الحقل مطلوب",
            }
        },
        en : {
            placeholder: "Animal body image",
            validations: {
                required: "this field is required",
            }
        }
    },
    {
        type: "file",
        id: "img",
        rules: {
            required: true,
        },
        ar : {

            placeholder: "صورة الحيوان",
            validations: {
                required: "هذا الحقل مطلوب",
            }
        },
        en : {
            placeholder: "Animal image",
            validations: {
                required: "this field is required",
            }
        }
    },
    {
        type: "file",
        id: "video",
        rules: {
            required: true,
        },
        ar : {

            placeholder: "فيديو للحيوان",
            validations: {
                required: "هذا الحقل مطلوب",
            }
        },
        en : {
            placeholder: "Animal video",
            validations: {
                required: "this field is required",
            }
        }
    },
    {
        type: "file",
        id: "imgs",
        multiple: true,
        rules: {
            required: true,
        },
        ar : {

            placeholder: " اضافة معرض صور",
            validations: {
                required: "هذا الحقل مطلوب",
            }
        },
        en : {
            placeholder: "Add gallery images",
            validations: {
                required: "this field is required",
            }
        }
    },
    {
        type: "text",
        id: "price",
        rules: {
            required: true,
            pattern: /^\d+(\.\d{1,2})?$/
        },
        ar : {
            
            placeholder: "السعر ",
            validations: {
                required: "رجاء قم بكتابة السعر المطلوب",
                pattern: "رجاء قم بكتابة سعر صحيح ",
            }
        },
        en : {

            placeholder: "Price",
            validations: {
                required: "please enter a price",
                pattern: "please enter a valid price",
            }
        }
    },
    {
        type: "textarea",
        id: "description",
        rules: {
            required: true,
        },
        ar: {

            placeholder: "اكتب وصف",
            validations: {
                required: "هذا الحقل مطلوب",
            },
        },
        en: {
            placeholder: "Description",
            validations: {
                required: "this field is required",
            },
        }
    },
];

export const PROFILE_LINKS = [
    {
        text: "البيانات الشخصية",
        en: "Personal Information",
        to: "/profile/details",
        icon: <TbListDetails />,
    },
    {
        text: "العناصر المعروضة للمزايدة",
        en: "My Bids",
        to: "/profile/mybids",
        icon: <BiDollar />,
    },
    {
        text: "عناصري المعروضة للبيع",
        en: "My Elements",
        to: "/profile/myelements",
        icon: <GiReceiveMoney />,
    },
];

export const USER = {
    fname: "Mohamed",
    lname: "Mahmoud",
    accRole: "مزايد",
    email: "mohamed@gmail.com",
    passportNumber: "123245688",
    phone: "1234567890",
    profileImage:
        "https://cdn4.vectorstock.com/i/1000x1000/56/78/businessman-profile-colorful-avatar-isolated-flat-vector-9685678.jpg",
};

export const EDIT_DETAILS_INPUTS = [
    {
        type: "text",
        id: "fullName",
        rules: {
            required: true,
            minLength: 4,
            maxLength: 15,
        },
        ar: {
            placeholder: "الاسم",
            validations: {
                required: "هذا الحقل مطلوب",
                minLength: "من فضلك قم بادخال اسم ما بين 4 و 15 حرف",
                maxLength: "من فضلك قم بادخال اسم ما بين 4 و 15 حرف",
            },
        },
        en: {
            placeholder: "Name",
            validations: {
                required: "This field is required",
                minLength: "Please enter a name between 4 and 15 characters",
                maxLength: "Please enter a name between 4 and 15 characters",
            },
        },
    },
    EMAIL_INPUT,
    PHONE_INPUT,
    {
        type: "text",
        id: "passportNumber",
        rules: {
            required: true,
            pattern: /^[A-Z0-9]{6,20}$/,
        },
        en: {
            placeholder: "Passport Number",
            validations: {
                required: "This field is required",
                pattern: "Please enter a valid passport number",
            },
        },
        ar: {
            placeholder: "رقم جواز السفر",
            validations: {
                required: "هذا الحقل مطلوب",
                pattern: "من فضلك قم بادخال رقم جواز سفر صالح",
            },
        },
    },
];
export const ADD_TO_BID_INPUTS = [
    {
        type: "number",
        id: "numOfDays",
        rules: {
            required: true,
            min: 2,
            max: 10,
        },
        ar: {
            placeholder: "عدد ايام المزايدة",
            validations: {
                required: "هذا الحقل مطلوب",
                min: "من فضلك قم بادخال عدد ايام المزايدة بين يومان و 10 ايام",
                max: "من فضلك قم بادخال عدد ايام المزايدة بين يومان و 10 ايام",
            },
        },
        en: {
            placeholder: "Number of days to bid",
            validations: {
                required: "This field is required",
                min: "Please enter the number of days to bid between 2 and 10 days",
                max: "Please enter the number of days to bid between 2 and 10 days",
            },
        },
    },
    {
        type: "number",
        id: "minPrice",
        rules: {
            required: true,
        },
        ar: {
            placeholder: "أقل سعر مطلوب للمزايدة",
            validations: {
                required: "هذا الحقل مطلوب",
            },
        },
        en: {
            placeholder: "Minimum bid price",
            validations: {
                required: "This field is required",
            },
        },
    },
];

export const CONTACT_INPUTS = [
    { ...NAME_INPUT, login: false },
    { ...PHONE_INPUT, login: false },
    { ...EMAIL_INPUT, login: false },
    {
        type: "text",
        id: "subject",
        login: true,
        rules: {
            required: true,
            maxLength: 15,
        },
        ar: {
            placeholder: "الموضوع",
            validations: {
                required: "هذا الحقل مطلوب",
                maxLength: "من فضلك قم بادخال اسم موضوع مختصر",
            },
        },
        en: {
            placeholder: "Subject",
            validations: {
                required: "This field is required",
                maxLength: "Please enter a short subject",
            },
        },
    },
    {
        type: "textarea",
        id: "message",
        login: true,
        rules: {
            required: true,
        },
        ar: {
            placeholder: "اكتب رسالتك",
            validations: {
                required: "هذا الحقل مطلوب",
            },
        },
        en: {
            placeholder: "Message",
            validations: {
                required: "This field is required",
            },
        },
    },
];

export const PAYMENT_INPUTS = [
    {
        type: "text",
        id: "firstName",
        rules: {
            required: true,
        },
        ar: {
            placeholder: " الاسم الاول",
            validations: {
                required: "هذا الحقل مطلوب",
            },
        },
        en: {
            placeholder: " firstName",
            validations: {
                required: "This field is required",
            },
        },
    },
    {
        type: "text",
        id: "lastName",
        rules: {
            required: true,
        },
        ar: {
            placeholder: " الاسم الاخير",
            validations: {
                required: "هذا الحقل مطلوب",
            },
        },
        en: {
            placeholder: " lastName",
            validations: {
                required: "This field is required",
            },
        },
    },
    {
        type: "text",
        id: "email",
        rules: {
            required: true,
        },
        ar: {
            placeholder: " البريد الالكتروني",
            validations: {
                required: "هذا الحقل مطلوب",
            },
        },
        en: {
            placeholder: " email",
            validations: {
                required: "This field is required",
            },
        },
    },
    {
        type: "text",
        id: "phone",
        rules: {
            required: true,
        },
        ar: {
            placeholder: " رقم الهاتف",
            validations: {
                required: "هذا الحقل مطلوب",
            },
        },
        en: {
            placeholder: " phone",
            validations: {
                required: "This field is required",
            },
        },
    },
];

export const FEED_BACK_INPUTS = [
    {
        type: "textarea",
        id: "message",
        login: true,
        rules: {
            required: true,
        },
        ar: {
            placeholder: "اكتب رسالتك",
            validations: {
                required: "هذا الحقل مطلوب",
            },
        },
        en: {
            placeholder: "feedback",
            validations: {
                required: "This field is required",
            },
        },
    },
];