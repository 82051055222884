import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Fragment, useState } from "react";
import Loader from "../../../components/Loader/Loader";
import ItemCard from "../../../components/ItemCard/ItemCard";
import { useSelector } from "react-redux";
import useAuthenticatedQuery from "../../../hooks/useAuthenticatedQuery";
import Modal from "../../../components/Modal/Modal";
import mainAxiosInstance from "../../../config/axiosInstance";
import toast from "react-hot-toast";
import Input from "../../../components/Input/Input";
import { ADD_TO_BID_INPUTS } from "../../../data";
import { useForm } from "react-hook-form";
import InputErrorMessage from "../../../components/InputErrorMessage/InputErrorMessage";
import TrainerRadioBox from "../../../components/TrainerRadioBox/TrainerRadioBox";
import TrainerModal from "../../../components/TrainerModal/TrainerModal";

const settings = {
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 3,
  initialSlide: 6,
  autoplay: true,
  speed: 500,
  autoplaySpeed: 4000,
  arrows: false,
  responsive: [
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const closeModal = document.querySelector("#closeModal");

const reomveShow = () => closeModal?.dispatchEvent(new Event("click"));
function MyElemnts() {
  const [submiting, setSubmiting] = useState(false);
  const [currentItemId, setCurrentItemId] = useState(24);
  const [update, setUpdate] = useState(1);
  const { lang } = useSelector((state) => state.lang);
  const { user, token } = useSelector((state) => state.user);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    try {
      setSubmiting(true);
      const endDate = new Date();
      const now = new Date();
      endDate.setDate(endDate.getDate() + Number(data.numOfDays));
      const addToBid = {
        biddingNo: 0,
        userId: user.id,
        title: currentItem.name,
        isActive: true,
        itemNo: currentItemId,
        startDate: now.toISOString(),
        endDate: endDate.toISOString(),
        price: data.minPrice,
      };
      const response = await mainAxiosInstance.post("api/bid", addToBid, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        toast.success(
          lang === "en"
            ? "Added to bid successfully"
            : "تمت اضافة الى المزايدة بنجاح",
          {
            position: "top-center",
            duration: 1500,
            style: {
              backgroundColor: "black",
              color: "white",
              width: "fit-content",
            },
          }
        );
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data);
    } finally {
      setTimeout(() => {
        reomveShow();
        setSubmiting(false);
      }, 500);
    }
  };

  const { isLoading, data } = useAuthenticatedQuery({
    queryKey: ["items", update],
    url: `api/item/filterByUser/${user.id}`,
    config: {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  });

  const deleteErrors = (id) => {
    if (id === currentItemId) return;
    const addModalForm = document.querySelector("#addToBid");
    addModalForm.reset();
    Object?.keys(errors).forEach((k) => delete errors[k]);
  };

  const deleteItem = async () => {
    try {
      setSubmiting(false);
      await mainAxiosInstance.delete(`api/item/DeleteItem/${currentItemId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUpdate((prev) => prev + 1);
      toast.success(
        lang === "en" ? `Item deleted successfully` : `تم مسح العنصر بنجاح`,
        {
          position: "top-center",
          duration: 1500,
          style: {
            backgroundColor: "black",
            color: "white",
            width: "fit-content",
          },
        }
      );
    } catch (error) {
      console.log(error);
    } finally {
      setTimeout(() => {
        reomveShow();
        setSubmiting(false);
      });
    }
  };

  if (isLoading) return <Loader />;

  const items = data?.result.filter((item) => item.isActive === true);
 
  const currentItem = items.find((item) => item.id === currentItemId);

  return (
    <Fragment>
      <h2 className="my-4">{lang === "en" ? "My elements" : "ممتلكاتي"} :</h2>
      <div className="items-section px-4 my-5">
        {items.length > 2 ? (
          <Slider {...settings}>
            {items.map((item) => (
              <ItemCard
                key={item.id}
                item={item}
                slider={true}
                bid={false}
                edits={true}
                setCurrentItemId={setCurrentItemId}
                deleteErrors={deleteErrors}
              />
            ))}
          </Slider>
        ) : (
          <div className="d-flex flex-wrap">
            {items.map((item) => (
              <ItemCard
                key={item.id}
                item={item}
                slider={true}
                bid={false}
                edits={true}
                setCurrentItemId={setCurrentItemId}
                deleteErrors={deleteErrors}
              />
            ))}
          </div>
        )}
      </div>
      <Modal
        title={lang === "en" ? "Delete item" : "حذف العنصر"}
        id="confirmDeleteItemModal"
        footer={
          <Fragment>
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              {lang === "en" ? "Cancel" : "الغاء"}
            </button>
            <button
              type="button"
              className="btn btn-danger"
              data-bs-dismiss="modal"
              onClick={() => deleteItem()}
            >
              {lang === "en" ? "Delete" : "حذف"}
            </button>
          </Fragment>
        }
      >
        <div className="modal-body">
          <p>
            {lang === "en"
              ? `Are you sure you want to delete ${currentItem?.name} ?`
              : `هل تريد حذف ${currentItem?.name} ؟`}
          </p>
        </div>
      </Modal>
      <TrainerModal itemid={currentItemId} />
      <form id="addToBid" onSubmit={handleSubmit(onSubmit)}>
        <Modal
          title={lang === "en" ? "Add to bid" : "اضافة للمزايدة"}
          id="addToBidModal"
          footer={
            <Fragment>
              <button
                type="button"
                className="btn btn-secondary"
                id="closeModal"
                data-bs-dismiss="modal"
              >
                {lang === "en" ? "Cancel" : "الغاء"}
              </button>
              <button type="submit" className="btn btn-success">
                {lang === "en" ? "Submit" : "تأكيد"}
              </button>
            </Fragment>
          }
        >
          <div className="modal-body">
            <p>
              {lang === "en"
                ? `Are you sure you want to add ${currentItem?.name} to bid?`
                : `هل تريد اضافة ${currentItem?.name} للمزايدة ؟`}
            </p>
            {ADD_TO_BID_INPUTS.map(({ type, rules, en, ar, id }) => (
              <Fragment key={id}>
                <div className="position-relative">
                  <Input
                    type={type}
                    {...register(id, rules)}
                    placeholder={
                      lang === "en" ? en.placeholder : ar.placeholder
                    }
                    error={errors[id] && true}
                  />
                </div>
                {errors[id] && (
                  <InputErrorMessage
                    msg={
                      lang === "en"
                        ? en.validations[errors[id].type]
                        : ar.validations[errors[id].type]
                    }
                  />
                )}
              </Fragment>
            ))}
          </div>
        </Modal>
      </form>
    </Fragment>
  );
}

export default MyElemnts;
