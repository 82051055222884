import { Fragment } from "react"
import InfoCard from "../InfoCard/InfoCard"
import { useSelector } from "react-redux"
import useAuthenticatedQuery from "../../hooks/useAuthenticatedQuery"
import Loader from "../Loader/Loader"

function Advantages() {
    const {lang} = useSelector(state => state.lang)

const {data , isLoading } = useAuthenticatedQuery({
    queryKey: ["advantages"],
    url: "api/advantages",
    config: {},
})

    return (
    <Fragment>
    {
        isLoading ? <Loader /> : data.result.map((advantage) => (
            
            <InfoCard
            title={
                lang === "en" ? advantage.titleEn : advantage.titleAr
            }
           
            linkText={
                lang === "en"
                    ? advantage.textEn
                    : advantage.textAr
            }
        />
        ))
    }
    </Fragment>
    )
}

export default Advantages
