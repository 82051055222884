import { Outlet } from "react-router-dom";
import MainNav from "../../components/Navbar/MainNav";
import Footer from "../../components/Footer/Footer";
import AdsBot from "../../components/AdsBot/AdsBot";
import AdsSide from "../../components/AdsSide/AdsSide";

function MainLayout() {
    return (
        <>
            <MainNav />
            <main>
                <Outlet />
                <AdsBot />
                <AdsSide />
            </main>
            <Footer />
        </>
    );
}

export default MainLayout;
