import { Link } from "react-router-dom";
import "./FormContainer.css";
import { useDispatch, useSelector } from "react-redux";
import { swapLang } from "../../slices/langSlice";

function FormContainer({ children, bg }) {
    const { lang } = useSelector((state) => state.lang);
    const dispatch = useDispatch();
    return (
        <div
            className={`py-5 d-flex align-items-center justify-content-center form-container ${bg}`}
        >
            <div
                className="p-4 border rounded-5  position-relative "
                style={{
                    width: "700px",
                    maxWidth: "90%",
                    background: "#fff5",
                }}
            >
                <button
                    onClick={() => dispatch(swapLang())}
                    className="btn btn-primary position-absolute top-10 end-10"
                >
                    {lang === "en" ? "العربية" : "English"}
                </button>
                <Link
                    to="/"
                    className="d-block w-25 mx-auto  text-decoration-none"
                >
                    <img
                        alt="Logo"
                        className="w-100 py-3 text-center form-logo"
                        src="https://pngimg.com/uploads/camel/camel_PNG23404.png"
                    />
                    <h1 className="text-uppercase fw-bold titles-color text-center">
                        hijin
                    </h1>
                </Link>
                {children}
            </div>
        </div>
    );
}

export default FormContainer;
