import { Link } from "react-router-dom";
import { Fragment, useEffect } from "react";

import "./RefundPolicy.css";
import { useSelector } from "react-redux";
import useAuthenticatedQuery from "../../hooks/useAuthenticatedQuery";
import Loader from "../../components/Loader/Loader";

function RefundPolicy() {
    const { lang } = useSelector((state) => state.lang);
    const { logedIn } = useSelector((state) => state.user);
    const { isLoading, data } = useAuthenticatedQuery({
        queryKey: ["policy_refund"],
        url: "api/policy_refund",
        config: {},
    });
    useEffect(() => {
        if (isLoading) return;
        const refund = new DOMParser().parseFromString(
            lang === "en" ? data.result.textEn : data.result.textAr,
            "text/html"
        );
        document.getElementById("refund").innerHTML = refund.body.innerHTML;
    }, [data, lang]);
    if (isLoading) return <Loader />;
    return (
        <Fragment>
            <section className="refund-page page-title align-items-center justify-content-center d-flex">
                <h1 className="text-center fw-bold text-light">
                    {lang === "en" ? "Refund Policy" : " سياسة الاسترداد"}
                </h1>
            </section>
            <section className="container py-5">
                <div id="refund"></div>
                {logedIn ? null : (
                    <Link
                        to="/register"
                        className="btn btn-primary d-block w-50 mx-auto "
                    >
                        {lang === "en" ? "Register now" : "سجل أﻷن"}
                    </Link>
                )}{" "}
            </section>
        </Fragment>
    );
}

export default RefundPolicy;
